/**
 * Responsive main menu
 */

 import $ from "jquery";

 export function responsiveMenu() {

  var $menu = $('.navigation-hamburger');

  if ($menu.length > 0) {
    var $toggle = $('<a>').attr({
      id: 'toggleMenu',
      href: '#'
    }).insertBefore($menu);

    $toggle.on('click', function (e) {
      e.preventDefault();
      $menu.animate({
        left: "0px"
      }, {
        duration: "slow"
      });
    });

    $("#closeMenu").on('click', function (e) {
      e.preventDefault();
      $menu.animate({
        left: "-400px"
      }, {
        duration: "slow"
      });

    });
  }
}
