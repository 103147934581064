'use strict';

import $ from "jquery";

import { responsiveMenu } from './lib/responsive-menu';
import { showContent } from './lib/show-content';

$(document).ready(function () {

	responsiveMenu();
	showContent();
});