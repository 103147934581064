/**
 * Responsive main menu
 */

 import $ from "jquery";

 export function showContent() {

  var $show = $('.contactlist__description');
  $show.hide();

  var $showbtn = $('.show-description');
  $showbtn.children("span").removeClass("opened");
  $showbtn.children("span").addClass("closed");

  if ($show.length > 0) {
    $showbtn.on('click', function (e) {
      e.preventDefault();
      $(this).next($show).slideToggle(300); 
      $(this).children("span").toggleClass("closed opened"); 
    });
  }
}
